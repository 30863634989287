import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/library/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    department:'',
    category:'',
    sub:'',
    type:3,
    author:'',
  },
  getters:{
    getDepartment(state){
      return state.department;
    },
    getCategory(state){
      return state.category;
    },
    getSub(state){
      return state.sub;
    },
    getType(state){
      return state.type;
    },
    getAuthor(state){
      return state.author;
    },
  },
  mutations:{
    reset(state){
      state.department='',
      state.category='',
      state.sub='',
      state.type=3;
      state.author='';
    },
    setDepartment(state,x){
      state.department=x;
    },
    setCategory(state,x){
      state.category=x;
    },
    setSub(state,x){
      state.sub=x;
    },
    setType(state,x){
      state.type=x;
    },
    setAuthor(state,x){
      state.author=x;
    },
  },

  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
